import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import firebase from "gatsby-plugin-firebase"
import Button from 'react-bootstrap/Button'
import Header from "../components/header"
import Footer from "../components/footer"
import * as moment from 'moment'
interface Item {
  displayName: string,
  amount: number,
  timestamp: any
}
const DonorsList = () => {
  const [fetching, setFetching] = useState<boolean>(false)
  const [nameList, setNameList] = useState<Array<Item>>(undefined)
  const [lastVisible, setLastVisible] = useState(undefined)
  const [noMore, setNoMore] = useState<boolean>(false)
  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  const loadMore = async () => {
    setFetching(true)
    const db = firebase.firestore()
    let donors
    if (nameList) {
      donors = nameList
    }
    else {
      donors = []
    }
    if (lastVisible) {
      const querySnap = await db.collection('donors').orderBy('timestamp', 'desc').startAfter(lastVisible).limit(12).get()

      if (querySnap.docs.length > 0) {
        await querySnap.docs.map((doc) => {
          donors.push(doc.data())
        })
        setLastVisible(querySnap.docs[querySnap.docs.length - 1])
        setNameList([...donors])

      }
      else {
        setNoMore(true)
      }
    }
    setFetching(false)
  }
  useEffect(() => {
    const fetchData = async () => {
      setFetching(true)
      if (firebase) {
        try {
          const db = firebase.firestore()
          let donors
          if (nameList) {
            donors = nameList
          }
          else {
            donors = []
          }
          const querySnap = await db.collection('donors').orderBy('timestamp', 'desc').limit(12).get()
          setLastVisible(querySnap.docs[querySnap.docs.length - 1])
          for (const doc of querySnap.docs) {
            donors.push(doc.data())
          }
          setNameList([...donors])
        }
        catch (err) {
          console.log(err)
        }

      }
    }
    fetchData()
    setFetching(false)
  }, [])

  return (
    <>
      <div className="row mt-5" >
        {nameList && nameList.map(indv =>
          indv ? (
            <div className="col-md-4 col-12 my-2 text-center">
              <h5 className='mb-0'>
                {indv.displayName}
              </h5>
              <small style={{ opacity: 0.7 }}>{moment(indv.timestamp.toDate()).fromNow()}</small>
              <p>
                <span className="green">{formatNumber(indv.amount ?? 0)}</span> บาท
            </p>
            </div>
          ) : (
              <div className="col-md-4 col-12 my-0" />
            )
        )}
      </div>
      {noMore !== true &&
        <Button disabled={fetching} onClick={loadMore} className='mt-3 text-white' variant='link'>โหลดเพิ่ม</Button>
      }
    </>
  )
}

const Donor = () => (
  <Layout>
    <SEO title="THACO | ผู้สนับสนุน" name="donors" />
    <Header page="donors" />
    <section
      className="pb-5 text-center text-white"
      style={{ paddingTop: 150 }}
    >
      <div className="container" style={{ maxWidth: 800 }}>
        <h2 className="mb-5">
          <span className="bg-dark px-2 py-1">ผู้ร่วมบริจาค</span>
        </h2>
        <p>การแข่งขันจะเกิดขึ้นไม่ได้เลย หากไม่ได้รับการสนับสนุนจากการบริจาคของทุกท่าน ทีมงานขอขอบคุณจากหัวใจ</p>
      </div>
      <div className='container'>
        <DonorsList />
      </div>
    </section>
    <Footer />
  </Layout>
)

export default Donor
